"use client";

import Link from "next/link";
import { Trans } from "react-i18next";
import Links from "@/app/constants/links";
import Translations from "@/app/constants/translations";

export default function PartnerAnnouncement() {
    const emailSubject: string = encodeURIComponent(
        "Partnership Inquiry (<website_url>)"
    );

    return (
        <Trans 
            i18nKey={
                `${
                    Translations.HEADER
                }:announcement.partnership`
            }
            values={{
                businessEmail: Links.BUSINESS_EMAIL,
            }}
            components={{
                strong: <span className="font-semibold" />,
                redirectLink: (
                    <Link 
                        href={
                            Links.BUSINESS_EMAIL_LINK 
                            + "?subject=" + emailSubject
                        }
                        target="_blank"
                        className="underline"
                    />
                )
            }}
        />
    );
}
