"use client";

import LocalStorage from "@/app/constants/localStorage";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

export default function ReferrerId() {
    const searchParams = useSearchParams();
    const referrerId: string | null = searchParams.get(LocalStorage.REF);

    useEffect(() => {
        if (!referrerId) return;
        if (localStorage.getItem(LocalStorage.REFERRER_ID)) return;
        localStorage.setItem(LocalStorage.REFERRER_ID, referrerId);
    });

    return null;
}
