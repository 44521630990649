"use client";

import Translations from "@/app/constants/translations";
import { useTranslation } from "react-i18next";

export default function PricingIncludedTitle() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <h4
            className="text-center font-bold text-2xl text-neutral-950 
            dark:text-neutral-50"
        >
            {t(`${Translations.HOME}:pricing.included.title`)}
        </h4>
    );
}
