"use client";

import Link from "next/link";
import { useTranslation } from "react-i18next";
import Routes from "@/app/constants/routes";
import Translations from "@/app/constants/translations";

export default function AffiliatePanelButton() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <Link href={Routes.LOGIN}>
            <button 
                className="rounded-full shadow-lg backdrop-blur-sm px-8 py-2 
                truncate text-center bg-gradient-to-br from-rose-500 
                to-rose-600 lg:px-12 lg:py-3 lg:text-2xl dark:shadow-none 
                hover:scale-105 hover:shadow-xl transition-all ease-in-out 
                duration-300"
            >
                <span 
                    className="text-lg font-bold text-neutral-50 lg:text-xl 
                    dark:text-neutral-950"
                >
                    {t(`${Translations.HOME}:affiliate.button`)}
                </span>
            </button>
        </Link>
    );
}
