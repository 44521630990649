"use client";

import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Translations from "@/app/constants/translations";

export interface PricingIncludedProps {
    text: string;
};

export default function PricingIncluded(props: PricingIncludedProps) {
    const { t } = useTranslation(Translations.HOME);

    return (
        <li className="group flex flex-row items-center gap-2">
            <CheckCircleIcon
                className={
                    `h-5 w-5 rounded-full text-neutral-700 
                    dark:text-neutral-300 group-hover:bg-lime-500 
                    group-hover:text-neutral-50
                    dark:group-hover:text-neutral-950
                    transition-all ease-in-out duration-300`
                }
            />
            <span
                className="text-neutral-700 dark:text-neutral-300
                group-hover:text-lime-500 transition-all ease-in-out
                duration-300"
            >
                {t(props.text)}
            </span>
        </li>
    );
}
