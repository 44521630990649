"use client";

import Link from "next/link";
import { useTranslation } from "react-i18next";
import Routes from "@/app/constants/routes";
import Translations from "@/app/constants/translations";
import { BONUS } from "@/app/constants/packages";

export default function HeroSectionButton() {
    const { t } = useTranslation(Translations.HOME);
    const discount: number = BONUS / (1 + BONUS) * 100;

    return (
        <section className="relative flex flex-col gap-2 w-fit">
            <section 
                className="group hover:scale-105 transition-all ease-in-out 
                duration-300"
            >
                <span 
                    className="absolute -top-5 -right-4 p-2 z-10 rounded-full 
                    shadow-lg bg-neutral-50 text-xs text-neutral-950 
                    dark:shadow-none dark:bg-neutral-800 
                    dark:text-neutral-50"
                >
                    {t(`${Translations.HOME}:hero.button.discount`, { 
                        discount: discount.toFixed(0)
                    })}
                </span>
                <Link href={Routes.LOGIN}>
                    <button 
                        className="px-8 py-2 rounded-full bg-gradient-to-br 
                        from-rose-500 to-rose-600 truncate 
                        font-semibold backdrop-blur-sm shadow-lg text-center 
                        lg:px-12 lg:py-3 dark:shadow-none 
                        group-hover:shadow-xl"
                    >
                        <span 
                            className="text-lg text-neutral-50 font-bold 
                            lg:text-xl"
                        >
                            {t(`${Translations.HOME}:hero.button.title`)}
                        </span>
                    </button>
                </Link>
            </section>
            <span 
                className="text-center text-xs text-neutral-700 
                dark:text-neutral-200"
            >
                {t(`${Translations.HOME}:hero.button.subtitle`)}
            </span>
        </section>
    );
}
