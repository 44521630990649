export interface Package {
    price: number;
    credits: number;
    popular?: boolean;
};

export const PACKAGES: Package[] = [
    {
        price: 799,
        credits: 60,
    },
    {
        price: 1599,
        credits: 275,
    },
    {
        price: 2799,
        credits: 825,
        popular: true,
    },
    {
        price: 4099,
        credits: 1500,
    },
];

export const BONUS: number = 0.3;
