"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export default function FeaturedBrandsText() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <span
            className="content-center text-center text-sm font-medium 
            text-neutral-400 dark:text-neutral-600"
        >
            {t(`${Translations.HOME}:featured.title`)}
        </span>
    );
}
