"use client";

import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

const ReactTyped = dynamic(
    () => import("react-typed").then(module => module.ReactTyped), 
    { ssr: false }
);

export default function HeroSectionTextAnimated() {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState<boolean>(false);

    const words: string[] = [
        t(`${Translations.HOME}:hero.title-word.crush`),
        t(`${Translations.HOME}:hero.title-word.girlfriend`),
        t(`${Translations.HOME}:hero.title-word.boss`),
        t(`${Translations.HOME}:hero.title-word.friend`),
        t(`${Translations.HOME}:hero.title-word.neighbor`),
        t(`${Translations.HOME}:hero.title-word.colleague`),
    ];

    useEffect(() => {
        setLoaded(true);
        if (!loaded) return;
        const defaultText = document.getElementById("default-text");
        if (!defaultText) return;
        defaultText.style.display = "none";
    }, [loaded]);

    return (
        <>
            <span 
                id="default-text"
                className="-mt-2 text-5xl text-rose-500 md:text-6xl 
                lg:text-7xl"
            >
                {words[0]}
            </span>
            {loaded && (
                <ReactTyped 
                    className="-mt-2 text-5xl text-rose-500 md:text-6xl 
                    lg:text-7xl" 
                    typeSpeed={100} 
                    backSpeed={50} 
                    strings={words} 
                    loop
                />
            )}
        </>
    );
}
