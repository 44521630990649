"use client";

import { useEffect, useState } from "react";

export default function AffiliateImageGradient() {
    const [isLtr, setIsLtr] = useState<boolean>(true);

    useEffect(() => setIsLtr(document.documentElement.dir === "ltr"), []);

    return (
        <figure
            className={`
                absolute inset-x-0 z-20 bottom-0 h-1/2 from-transparent 
                sm:inset-y-0 sm:end-0 sm:h-full sm:w-1/2 
                ${isLtr ? "bg-gradient-to-b sm:bg-gradient-to-l " + 
                "to-amber-300 sm:to-amber-400" : 
                "bg-gradient-to-t sm:bg-gradient-to-r " + 
                "to-amber-400 sm:to-amber-300"}`
            }
        />
    );
}
