"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export default function HeroLaurelWreathText() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <>
            <span 
                className="text-base text-neutral-700 md:text-medium 
                dark:text-neutral-300"
            >
                {t(`${Translations.HOME}:hero.award.title`)}
            </span>
            <span 
                className="leading-none text-lg font-extrabold 
                text-neutral-700 md:text-xl dark:text-neutral-300"
            >
                {t(`${Translations.HOME}:hero.award.rank`)}
            </span>
        </>
    );
}
