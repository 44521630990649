"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export default function AffiliateImageText() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <span
            className="absolute z-20 top-2 left-2 p-2 rounded-xl 
            bg-neutral-50 text-neutral-50 bg-opacity-25 backdrop-blur-sm 
            text-sm dark:bg-neutral-950 dark:text-neutral-300 
            dark:bg-opacity-10 md:hidden group-hover:opacity-0 
            transition-all ease-in-out duration-500"
        >
            {t(`${Translations.HOME}:affiliate.image-text`)}
        </span>
    );
}
