"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export default function HeroImageButtonText() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <span
            className="opacity-90 text-sm font-semibold 
            text-amber-400 group-hover:text-neutral-50
            md:text-base lg:font-bold"
        >
            {t(`${Translations.HOME}:hero.image-button`)}
        </span>
    );
}
