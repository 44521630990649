"use client";

import { Trans, useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

function OverviewDescriptionSteps() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <span className="font-bold text-rose-500">
            {t(`${Translations.HOME}:overview.description-steps`)}
        </span>
    );
}

export default function OverviewTitle() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <section 
            className="flex flex-col items-center justify-center 
            gap-4 text-center"
        >
            <h2 
                className="flex flex-col font-bold text-3xl 
                text-neutral-950 md:text-4xl lg:leading-tight 
                lg:text-5xl dark:text-neutral-50"
            >
                {t(`${Translations.HOME}:overview.title`)}
            </h2>
            <p 
                className="leading-relaxed text-balance text-base 
                text-neutral-700 md:text-lg lg:text-lg lg:font-medium 
                lg:w-2/3 dark:text-neutral-200"
            >
                <Trans
                    i18nKey={`${Translations.HOME}:overview.description`}
                    components={{
                        steps: <OverviewDescriptionSteps />
                    }}
                />
            </p>
        </section>
    );
}
