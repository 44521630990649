"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export interface OverviewCardTextProps {
    title: string;
    description: string;
};

export default function OverviewCardText(props: OverviewCardTextProps) {
    const { t } = useTranslation(Translations.HOME);

    return (
        <section className="flex flex-col gap-4 text-center items-center">
            <h3 
                className="flex flex-col gap-3 font-bold text-2xl 
                text-neutral-950 md:text-3xl lg:leading-tight 
                lg:text-4xl dark:text-neutral-50"
            >
                {t(props.title)}
            </h3>
            <p 
                className="leading-relaxed text-balance text-base 
                text-neutral-700 md:text-lg lg:text-wrap 
                lg:text-lg xl:w-full dark:text-neutral-200"
            >
                {t(props.description)}
            </p>
        </section>
    );
}
